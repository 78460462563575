<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="tabClick" class="text_table">
			<el-tab-pane label="报告管理" name="1" v-if="!$store.getters.permissionsName('报告管理')"></el-tab-pane>
			<el-tab-pane label="个体分析" name="2" v-if="!$store.getters.permissionsName('个体分析')"></el-tab-pane>
			<el-tab-pane label="联合分析" name="3" v-if="!$store.getters.permissionsName('联合分析')"></el-tab-pane>
			<!-- <el-tab-pane label="数据管理" name="4" v-if="!$store.getters.permissionsName('数据管理')"></el-tab-pane> -->
		</el-tabs>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeName: '0',
		}
	},
	watch: {
		$route(to, form) {
			var type = to.query.type
			this.activeName = type
		},
	},
	mounted() {
		if (this.$route.query.type) {
			this.activeName = this.$route.query.type
		} else {
			setTimeout(() => {
				if (!this.$store.getters.permissionsName('报告管理')) {
					this.activeName = '1'
				} else if (!this.$store.getters.permissionsName('个体分析')) {
					this.activeName = '2'
				} else if (!this.$store.getters.permissionsName('联合分析')) {
					this.activeName = '3'
				} else if (!this.$store.getters.permissionsName('数据管理')) {
					this.activeName = '4'
				}
				this.tabClick()
			}, 200)
		}
	},
	methods: {
		tabClick() {
			sessionStorage.removeItem('arch_search')

			if (this.activeName == '0') {
				let time = setInterval(() => {
					if (!this.$store.getters.permissionsName('报告管理')) {
						this.activeName = '1'
						clearInterval(time)
						this.create()
					} else if (!this.$store.getters.permissionsName('个体分析')) {
						this.activeName = '2'
						clearInterval(time)
						this.create()
					} else if (!this.$store.getters.permissionsName('联合分析')) {
						this.activeName = '3'
						clearInterval(time)
						this.create()
					} else if (!this.$store.getters.permissionsName('数据管理')) {
						this.activeName = '4'
						clearInterval(time)
						this.create()
					}
				}, 200)
			} else {
				this.create()
			}
		},
		create() {
			if (this.activeName == '1') {
				this.$router.push({ path: '/analysis/reportManagement', query: { type: 1 } })
			} else if (this.activeName == '2') {
				this.$router.push({ path: '/analysis/individualAnalysis', query: { type: 2 } })
			} else if (this.activeName == '3') {
				this.$router.push({ path: '/analysis/jointAnalysis', query: { type: 3 } })
			} else if (this.activeName == '4') {
				this.$router.push({ path: '/DataDocking/functionalMent', query: { type: 4 } })
			}
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/ .text_table {
	background: #fff;
	border-radius: 5px;
	.el-tabs__header {
		margin-bottom: 0;
		.el-tabs__nav-wrap::after {
			background: #fff;
			margin-left: 10px;
			width: 90%;
		}
		.el-tabs__nav {
			height: 55px;
			line-height: 55px;
			margin-left: 50px;
			font-size: 14px;
		}
		.el-tabs__item {
			font-size: 16px;
			// padding: 0 25px;
			&:hover {
				color: #0055e9;
			}
		}
		.is-active {
			color: #0055e9;
		}
		.el-tabs__active-bar {
			background-color: #0055e9;
		}
	}
}

.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}
</style>
